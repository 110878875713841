/* SuspenseFallback.css */

.vis-suspense-loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #f9c300, -20px 0 #f9c30033;
    background: #f9c300;
  }

  33% {
    box-shadow: 20px 0 #f9c300, -20px 0 #f9c30033;
    background: #f9c30033;
  }

  66% {
    box-shadow: 20px 0 #f9c30033, -20px 0 #f9c300;
    background: #f9c30033;
  }

  100% {
    box-shadow: 20px 0 #f9c30033, -20px 0 #f9c300;
    background: #f9c300;
  }
}
